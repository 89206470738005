import React from 'react'
import { Link } from 'react-router-dom'

export const AboutMe = () => {
  return (
    <div>
      Это наш сайт!!!
      <br />
      <Link to="/">На Главную!</Link>
    </div>
  )
}

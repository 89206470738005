export const ACTION_CHANGE_FIRST_NAME = 'ACTION_CHANGE_FIRST_NAME'
export const ACTION_CHANGE_SECOND_NAME = 'ACTION_CHANGE_SECOND_NAME'
export const ACTION_GET_DATA_OF_AUTHOR = 'ACTION_GET_DATA_OF_AUTHOR'

// export const changeFirstName = (newFirstName) => {
//     return {
//         type:ACTION_CHANGE_FIRST_NAME,
//         payload: newFirstName,
//     }
// }
//
// export const changeSecondName = (newSecondName) => {
//     return {
//         type:ACTION_CHANGE_SECOND_NAME,
//         payload: newSecondName,
//     }
// }
